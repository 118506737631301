import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Router from 'next/router';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Button from 'src/components/global/buttons/Control';
import Logo from 'src/components/global/logos/UExpress';
import PrimaryLinks from 'src/components/global/links/Primary';
import SearchLink from 'src/components/global/links/Search';
import AuxiliaryLinks from 'src/components/global/links/Auxiliary';
import Copyright from 'src/components/global/Copyright';

import parentStyles from './Header.module.scss';
import styles from './Mobile.module.scss';

const Mobile = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Disable body scroll while nav drawer is open
    const navElement = document.querySelector('#header-navigation');
    if (open) {
      disableBodyScroll(navElement);
    } else {
      enableBodyScroll(navElement);
      clearAllBodyScrollLocks();
    }

    // Close nav drawer when route changes
    const handleRouteChange = () => {
      setOpen(false);
    };
    Router.events.on('routeChangeStart', handleRouteChange);

    // Cleanup
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [open]);

  return (
    <div className={parentStyles.header__content}>
      <div className={parentStyles.header__side}>
        <Button
          onClick={() => setOpen(true)}
          buttonStyles={styles.mobile__open}
          ariaLabel="Open Header Navigation"
          ariaExpanded={open ? 'true' : 'false'}
          ariaControls="header-navigation"
        >
          <span className="oi oi-menu" />
        </Button>
      </div>
      <div className={parentStyles.header__main}>
        <Logo logoStyles={parentStyles.header__logo} navigationType="header" />
      </div>
      <div
        id="header-navigation"
        className={classNames(styles.mobile, {
          [styles.mobile_open]: open,
        })}
      >
        <nav className={styles.mobile__nav}>
          <Button
            onClick={() => setOpen(false)}
            buttonStyles={styles.mobile__close}
            ariaLabel="Close Header Navigation"
            ariaExpanded={open ? 'true' : 'false'}
            ariaControls="header-navigation"
          >
            <span>&times;</span>
          </Button>
          <div className={styles.mobile__logo}>
            <Logo
              logoStyles={`${parentStyles.header__logo} ${parentStyles.header__logo_open}`}
            />
          </div>
          <div
            className={classNames(
              styles.mobile__links,
              styles.mobile__links_primary
            )}
          >
            <PrimaryLinks addCategoryModifiers navigationType="header" />
            <SearchLink navigationType="header" />
          </div>
          <div
            className={classNames(
              styles.mobile__links,
              styles.mobile__links_auxiliary
            )}
          >
            <AuxiliaryLinks navigationType="header" />
          </div>
          <div className={styles.mobile__copyright}>
            <Copyright navigationType="header" />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Mobile;
