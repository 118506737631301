import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import Logo from 'src/components/global/logos/UExpress';
import PrimaryLinks from 'src/components/global/links/Primary';
import SearchLink from 'src/components/global/links/Search';

import parentStyles from './Header.module.scss';

const Desktop = () => {
  const router = useRouter();

  return (
    <div className={parentStyles.header__container}>
      <div
        className={classNames(
          parentStyles.header__content,
          parentStyles.header__content_desktop
        )}
      >
        <div className={parentStyles.header__side}>
          <Logo
            logoStyles={parentStyles.header__logo}
            navigationType="header"
          />
        </div>
        <div className={parentStyles.header__main}>
          <nav className="nav">
            <PrimaryLinks
              primaryLinkStyles={parentStyles.header__link}
              addCategoryModifiers
              navigationType="header"
              variant="header"
            />
          </nav>
        </div>
        <div className={parentStyles.header__side}>
          <SearchLink
            searchStyles={classNames(parentStyles.header__link, {
              [parentStyles.header__link_active]: router.asPath === '/search',
            })}
            displayIcon
            navigationType="header"
          />
        </div>
      </div>
    </div>
  );
};

export default Desktop;
