/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import dynamic from 'next/dynamic';

import { menuNavigationEvent } from 'src/lib/analytics';

const Sourcepoint = dynamic(() => import('src/components/footer/Sourcepoint'), {
  ssr: false,
});

const links = {
  legal: {
    href: 'https://www.andrewsmcmeel.com/terms-of-service',
    title: 'Terms of Service',
  },
  privacyPolicy: {
    href: 'https://www.andrewsmcmeel.com/privacy-policy/',
    title: 'Privacy Policy',
  },
};

const Auxiliary = ({
  containerStyles,
  linkStyles,
  navigationType,
  displayContext,
}) => (
  <nav className={containerStyles} aria-label="uExpress and legal">
    <Link href="/about">
      <a
        className={linkStyles}
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'about')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'about')
            : undefined
        }
      >
        About
      </a>
    </Link>

    <Link href="/contact">
      <a
        className={linkStyles}
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'contact')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'contact')
            : undefined
        }
      >
        Contact
      </a>
    </Link>

    <Link href="/submissions">
      <a
        className={linkStyles}
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'submissions')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'submissions')
            : undefined
        }
      >
        Submissions
      </a>
    </Link>

    {Object.keys(links).map((destination) => (
      <a
        href={`${links[destination].href}`}
        className={linkStyles}
        target="_blank"
        rel="noopener noreferrer"
        key={links[destination].href}
        onClick={
          navigationType
            ? () =>
                menuNavigationEvent(navigationType, links[destination].title)
            : undefined
        }
        onKeyUp={
          navigationType
            ? () =>
                menuNavigationEvent(navigationType, links[destination].title)
            : undefined
        }
      >
        {links[destination].title}
      </a>
    ))}

    {displayContext === 'footer' && <Sourcepoint linkStyles={linkStyles} />}
  </nav>
);

Auxiliary.propTypes = {
  containerStyles: PropTypes.string,
  linkStyles: PropTypes.string,
  navigationType: PropTypes.string,
  displayContext: PropTypes.string.isRequired,
};

Auxiliary.defaultProps = {
  containerStyles: '',
  linkStyles: '',
  navigationType: '',
};

export default Auxiliary;
