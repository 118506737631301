import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { menuNavigationEvent } from 'src/lib/analytics';

import styles from './Copyright.module.scss';

const Copyright = ({ copyrightStyles, amuStyles, navigationType }) => (
  <span className={copyrightStyles}>
    {`©${moment().year()} `}
    <a
      href="https://www.andrewsmcmeel.com/"
      className={classNames(styles.amu, amuStyles)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'copyright')
          : undefined
      }
      onKeyUp={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'copyright')
          : undefined
      }
    >
      Andrews McMeel Universal
    </a>
  </span>
);

Copyright.propTypes = {
  copyrightStyles: PropTypes.string,
  amuStyles: PropTypes.string,
  navigationType: PropTypes.string,
};

Copyright.defaultProps = {
  copyrightStyles: '',
  amuStyles: '',
  navigationType: '',
};

export default Copyright;
