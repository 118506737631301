import React from 'react';
import PropTypes from 'prop-types';

import styles from './TopAdvertisingWrapper.module.scss';

const TopAdvertisingWrapper = () => {
  return (
    <div className={styles.top} data-print="none">
      <p>Advertisement</p>
      <div className="amu-top-ad" />
    </div>
  );
};

export default TopAdvertisingWrapper;
