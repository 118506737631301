/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { menuNavigationEvent } from 'src/lib/analytics';

const SearchNavigation = ({ searchStyles, displayIcon, navigationType }) => (
  <Link href="/search">
    <a
      className={searchStyles}
      aria-label="search"
      onClick={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'search')
          : undefined
      }
      onKeyUp={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'search')
          : undefined
      }
    >
      <span className={displayIcon ? 'sr-only' : ''}>Search</span>
      {displayIcon ? <span className="oi oi-magnifying-glass" /> : null}
    </a>
  </Link>
);

SearchNavigation.propTypes = {
  searchStyles: PropTypes.string,
  displayIcon: PropTypes.bool,
  navigationType: PropTypes.string,
};

SearchNavigation.defaultProps = {
  searchStyles: '',
  displayIcon: false,
  navigationType: '',
};

export default SearchNavigation;
